import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    EmailID: "",
    refresh: false,
    getIssueDetails:{},
    GetCurrentUser: {},
    GetCurrentProject: {},
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_current_project_details: (state) => {
      return state.GetCurrentProject;
    },
    get_refresh: (state) => {
      return state.refresh;
    },
    get_issue_details:(state)=>{
      return state.getIssueDetails
    }
  },
  mutations: {
    SET_REFRESH(state, refresh) {
      state.refresh = refresh;
    },
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_CURRENTPROJECT_DETAILS(state, GetCurrentProjectDetails) {
      state.GetCurrentProject = GetCurrentProjectDetails;
    },
    SET_ISSUE_DETAILS(state,getIssueDetails){
      state.getIssueDetails = getIssueDetails;
    }
  },
  actions: {},
  modules: {},
});
 