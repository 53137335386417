import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/SignUp",
    name: "SignUp",
    component: LoginPage,
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: LoginPage,
  },
  {
    path: "/ProjectPage",
    name: "ProjectPage",
    component: () => import( "../views/MainHomePages/ProjectPage.vue"),
  },
  {
    path: "/HomeLandingPage",
    name: "HomeLandingPage",
    component: () => import(/* webpackChunkName: "HomeLandingPage" */ "../views/MainHomePages/HomeLandingPage.vue"),
    children: [
      {
        path: "/Issues",
        name: "Issues",
        component: () => import(/* webpackChunkName: "Issues" */ "../views/ChildHomePages/HomeLandingPage/Issues.vue"),
      },
      {
        path: "/Approvals",
        name: "Approvals",
        component: () => import(/* webpackChunkName: "Approvals" */ "../views/ChildHomePages/HomeLandingPage/Approvals.vue"),
      },
      {
        path: "/Members",
        name: "Members",
        component: () => import(/* webpackChunkName: "Testers" */ "../views/ChildHomePages/HomeLandingPage/Members.vue"),
      },
      {
        path: "/ProjectDetails",
        name: "ProjectDetails",
        component: () => import(/* webpackChunkName: "ProjectDetails" */ "../views/ChildHomePages/HomeLandingPage/ProjectDetails.vue"),
      },
      {
        path: "/Activities",
        name: "Activities",
        component: () => import(/* webpackChunkName: "ProjectDetails" */ "../views/ChildHomePages/HomeLandingPage/Activities.vue"),
      },
    ],
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import(/* webpackChunkName: "ProjectDetails" */ "../views/ChildHomePages/HomeLandingPage/ReportIssue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
