import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Amplify from "aws-amplify";
import store from "./store";
import "@/plugins/apexcharts";
Vue.config.productionTip = false;
// domain: "localhost:8080",
const oauth = {
  domain: "bugtrakr.auth.ap-southeast-1.amazoncognito.com",
  scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
  redirectSignIn: "https://www.bugtrakr.com/PORTAL/",
  redirectSignOut: "https://www.bugtrakr.com/PORTAL/",
  // redirectSignIn: "http://localhost:8080/",
  // redirectSignOut: "http://localhost:8080/",

  responseType: "code",
};

Amplify.configure({
  Auth: {
    userPoolId: "ap-southeast-1_4LkQhINN3",
    region: "ap-southeast-1",
    userPoolWebClientId: "7a9b1q673jhq546eboh6clk6t5",
    oauth: oauth,
  },
  API: {
    aws_project_region: "ap-southeast-1",
    aws_appsync_graphqlEndpoint: "https://kq5m4zeb5zg4vahce5slsy5c7i.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-southeast-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "BUGTRAKR",
        endpoint: "https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
