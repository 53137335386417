<template>
  <div align="left">
    <v-container fluid fill-height>
      <Snackbar :SnackBarComponent="SnackBarComponent" />
      <v-layout align-center justify-center>
        <v-flex>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="8" class="py-0" align="center">
              <v-card class="elevation-0">
                <div align="left">
                  <v-btn icon color="primary" dark href="https://www.bugtrakr.com/"><v-icon>mdi-home</v-icon></v-btn>
                </div>
                <v-img contain src="@/assets/BugTrakrBG.jpg" max-width="500px"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-card max-width="380px" height="400px" class="elevation-0" align="center">
                <v-card-title>
                  <v-row no-gutters>
                    <v-col cols="12" class="py-0" align="center">
                      <v-img width="140px" contain src="@/assets/BugTraker.png"></v-img>
                      <div class="pt-2">
                        {{ currentTitle }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-window v-model="loginStepper" class="px-3">
                    <!-- Start Of loginStepper 1 -->
                    <v-window-item :value="1">
                      <v-alert text class="mx-4 mb-n1 mt-2 py-2" color="red" v-if="ShowSocilError" dismissible align="left">
                      <span class="font-weight-bold dark_primary--text" style="font-size: 16px">Couldn't find your account</span> <br />
                      <span class="dark_primary--text" style="font-size: 16px"
                        >There's no account for that email. Try logging in with a different email.</span
                      >
                    </v-alert>
                      <v-card-text>
                        <v-form ref="form" @submit.prevent lazy-validation>
                          <v-text-field
                            outlined
                            dense
                            label="Email ID *"
                            class="borderRadius fontSize"
                            @keydown.space="preventLeadingSpace"
                            @keydown.enter="ValidateSignUpForm()"
                            prepend-inner-icon="mdi-account"
                            :rules="[(v) => !!v || 'Email ID is required']"
                            v-model="Login.EmailID"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            dense
                            label="Password *"
                            class="borderRadius fontSize"
                            :type="isPwdLogin ? 'text' : 'password'"
                            @click:append="isPwdLogin = !isPwdLogin"
                            @keydown.space="preventLeadingSpace"
                            @keydown.enter="ValidateSignUpForm()"
                            required
                            :append-icon="isPwdLogin ? 'mdi-eye' : 'mdi-eye-off'"
                            prepend-inner-icon="mdi-lock"
                            :rules="[(v) => !!v || 'Password is required']"
                            v-model="Login.Password"
                          ></v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="py-0">
                        <v-checkbox dense class="my-0 py-0 ml-1" v-model="rememberme" label="Remember Me" :disabled="Login.EmailID === '' && Login.Password === ''" />
                        <v-spacer></v-spacer>
                        <v-btn x-small text class="fontSize mb-4" color="primary text-capitalize fontStyle" @click="loginStepper = 3">Forgot Password ?</v-btn>
                      </v-card-actions>
                      <v-card-actions class="pt-0 pb-2 px-4">
                        <v-btn block :loading="loading" tile class="borderRadius fontStyle" color="primary" @click="ValidateSignUpForm()">Login</v-btn>
                      </v-card-actions>
                      <v-row> <v-card-text class="fontSizeLarge font-weight-black">or continue with</v-card-text> </v-row>
                      <div class="pb-2">
                        <v-card class="elevation-0">
                          <v-icon @click="signIn_facebook" large color="primary" class="mr-10">mdi-facebook</v-icon>
                          <v-icon large color="primary" @click="signIn_google" class="ml-10">mdi-google</v-icon>
                        </v-card>
                      </div>

                      <v-divider></v-divider>
                      <div class="fontSizeLarge font-weight-black">
                        Don't have an account?
                        <v-btn small text color="primary" class="text-capitalize" @click="loginStepper = 5"><u>Sign up</u></v-btn>
                      </div>
                      <v-card-text class="mt-1 pt-0 text-center">
                        <div>
                          Facing an issue using BugTrakr ?
                          <v-btn x-small text color="primary" @click="openBugTrakrForm()">Report</v-btn>
                        </div>
                      </v-card-text>

                      <!-- <div class="fontSize">Sign in with one of the following</div> -->
                      <!-- <div>
                      <v-btn large icon color="primary" @click="checkSignInMethod('Google')">
                        <v-icon>mdi-google</v-icon>
                      </v-btn>
                      <v-btn large icon color="primary" class="mx-4" @click="checkSignInMethod('Facebook')">
                        <v-icon>mdi-facebook</v-icon>
                      </v-btn>
                      <v-btn large icon color="primary">
                        <v-icon>mdi-apple</v-icon>
                      </v-btn>
                    </div> -->
                    </v-window-item>
                    <!-- End Of loginStepper 1 -->
                    <!-- Start Of loginStepper 2 -->
                    <v-window-item :value="2">
                      <v-card-text>
                        <v-form ref="formCP" lazy-validation>
                          <v-text-field
                            class="borderRadius fontSize"
                            outlined
                            dense
                            label="Enter New Password *"
                            :type="isPwdNew ? 'text' : 'password'"
                            @click:append="isPwdNew = !isPwdNew"
                            required
                            :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                            prepend-inner-icon="mdi-lock"
                            :rules="[(v) => !!v || 'Password is required']"
                            v-model="Password.NewPassword"
                          ></v-text-field>
                          <v-text-field
                            class="borderRadius fontSize"
                            outlined
                            dense
                            label="Confirm New Password *"
                            :type="isPwdConfirm ? 'text' : 'password'"
                            @click:append="isPwdConfirm = !isPwdConfirm"
                            required
                            :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                            prepend-inner-icon="mdi-lock"
                            :rules="[(v) => !!v || 'Password is required']"
                            v-model="Password.ConfirmPassword"
                          ></v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="pt-0 pb-8 px-4">
                        <v-btn block :loading="loading" tile class="borderRadius fontStyle" color="primary" @click="ValidateConfirmPasswordForm()">Login</v-btn>
                      </v-card-actions>
                    </v-window-item>
                    <!-- End Of loginStepper 2 -->
                    <!-- Start Of loginStepper 3 -->
                    <v-window-item :value="3">
                      <v-card-text>
                        <v-form ref="formOTP">
                          <div class="text-center">Please Enter The Registered Email</div>
                          <v-text-field dense outlined label="Email" v-model="OTPEmail" :rules="[(v) => !!v || 'Email ID is required']" class="mt-4 borderRadius fontSize"> </v-text-field>
                          <div class="fontSize text-center">*Please enter the e-mail used for BugTrakr login/registration</div>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="primary fontStyle" small @click="loginStepper = 1">Back To Sign In</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary fontStyle" small @click="ValidateOTP()">Next</v-btn>
                      </v-card-actions>
                    </v-window-item>
                    <!-- End Of loginStepper 3 -->
                    <!-- Start Of Step 4 -->
                    <v-window-item :value="4">
                      <v-card-text>
                        <v-form ref="formConfirmOTP">
                          <div class="text-center">Enter the BugTrakr Activation Code</div>
                          <v-text-field dense outlined label="Code *" v-model="ConfirmOTP" :rules="[(v) => !!v || 'Code is required']" class="mt-4 borderRadius"> </v-text-field>
                          <v-text-field
                            dense
                            outlined
                            label="Enter New Password *"
                            v-model="ConfirmNewPassword"
                            :rules="[(v) => !!v || 'Password is required']"
                            class="borderRadius fontSize mt-4"
                            :type="isPwdConfirmCode ? 'text' : 'password'"
                            @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                            required
                            :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                            prepend-inner-icon="mdi-lock"
                          >
                          </v-text-field>
                          <div class="fontSize text-center">Enter the BugTrakr Activation Code sent to your registered Email.</div>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="primary" class="fontStyle borderRadius" small @click="loginStepper = 1">Back To Sign In</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" class="fontStyle borderRadius" small @click="ValidateEnteredOTP()">Next</v-btn>
                      </v-card-actions>
                    </v-window-item>
                    <!-- End Of Step 4-->
                    <!-- Start Of Step 5 -->
                    <v-window-item :value="5">
                      <v-card-text class="text-left pb-0">
                        <v-form ref="formSignUp">
                          <div class="fontSize">Please Fill The Following Details</div>
                          <!-- <v-text-field dense outlined label="Name *" v-model="sign_up.user_name" :rules="[(v) => !!v || 'Required']" class="mt-4 borderRadius fontSize"> </v-text-field> -->
                          <v-text-field
                            dense
                            outlined
                            label="Email ID *"
                            v-model="sign_up.user_email_id"
                            :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
                            class="borderRadius fontSize"
                          >
                          </v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="pt-0 mx-2">
                        <v-btn tile small block color="primary" @click="validateSignUpMethod()" :loading="loading">Sign Up</v-btn>
                      </v-card-actions>
                      <v-btn text color="primary" class="fontStyle borderRadius text-capitalize" small @click="loginStepper = 1"><u>Back To Sign In</u></v-btn>
                    </v-window-item>
                    <!-- End Of Step 4-->
                  </v-window>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth, API, Hub } from "aws-amplify";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    ShowSocilError: false,
    isPwdNew: false,
    isPwdLogin: false,
    isPwdConfirm: false,
    isPwdConfirmCode: false,
    loginStepper: 1,
    rememberme: false,
    loading: false,
    Login: {
      EmailID: "",
      Password: "",
    },
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    sign_up: {
      user_name: "",
      user_email_id: "",
    },
    OTPEmail: "",
    ConfirmOTP: "",
    ConfirmNewPassword: "",
    SnackBarComponent: {},
    countryCodeItems: [
      { country_name: "India", country_code: "+91" },
      { country_name: "USA", country_code: "+1" },
    ],
  }),
  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("currentuseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.currentuseremail;
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.loginStepper) {
        case 1:
          return "Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        case 5:
          return "Sign Up";
        default:
          return "Account created";
      }
    },
  },
  mounted() {
    this.CheckRememberMe();
    this.checkRouteCondition();
    try {
      Hub.listen("auth", (data) => {
        if (data.payload.event === "signIn") {
          // window.console.warn(`current user: ${JSON.stringify(data.payload.data)}`);
          console.log(data.payload.data.signInUserSession.idToken.payload.email);
          this.$store.commit("SET_USEREMAIL", data.payload.data.signInUserSession.idToken.payload.email);
          this.$router.push("/ProjectPage");
        } else if (data.payload.event === "signOut") {
          // window.console.warn(`current user: ${JSON.stringify(data.payload.data)}`);
        }
        console.log(String(data.payload.data));
  
        if (
          String(data.payload.data) == "Error: PreAuthentication+failed+with+error+Incorrect+Username+or+Password.+" ||
          String(data.payload.data) == "Error: PreSignUp+failed+with+error+Incorrect+Username+or+Password.+"
        ) {
          this.ShowSocilError = true;
        }
      });
    } catch (err) {
      console.log("SSO Error", err);
    }
  },
  methods: {
    openwebPage() {
      this.$router.push("https://www.bugtrakr.com/");
    },
    async signIn_facebook() {
      console.log('SocialSign Facebook')
      this.overlay = true;
      await Auth.federatedSignIn({ provider: "Facebook" });
      this.overlay = false;
    },
    async signIn_google() {
      console.log('SocialSign Google')
      this.overlay = true;
      await Auth.federatedSignIn({ provider: "Google" });
      this.overlay = false;
    },
    checkRouteCondition() {
      let routeparam = this.$route.name.toLowerCase();
      switch (routeparam) {
        case "signup":
          this.loginStepper = 5;
          break;
        case "Signin":
          this.loginStepper = 1;
          break;
      }
    },
    openBugTrakrForm() {
      window.open("http://www.bugtrakr.com/BugReportForm/BUGTRAKR");
    },
    validateSignUpMethod() {
      if (this.$refs.formSignUp.validate()) {
        this.signUpMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Input",
        };
      }
    },
    async signUpMethod() {
      var self = this;
      self.loading = true;
      const path = "user_signup";
      const myInit = {
        body: {
          command: "userSignup",
          user_email_id: this.sign_up.user_email_id.toLowerCase(),
        },
      };
      API.post("BUGTRAKR", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.loginStepper = 1;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.message,
            };
            this.$refs.formSignUp.reset();
          } else {
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          }
          self.loading = false;
        })
        .catch(() => {
          self.loading = true;
        });
    },
    // async listenMethod() {
    //   const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
    //     switch (event) {
    //       case "signIn":
    //         console.log("Check event", event);
    //         console.log("Check Data", data);
    //         break;
    //       case "signOut":
    //         console.log("Check event", event);
    //         console.log("Check Data", data);
    //         break;
    //     }
    //   });
    //   console.log("Check unsubscribe", unsubscribe);

    //   Auth.currentAuthenticatedUser()
    //     .then((response) => {
    //       console.log("Check Response", response);
    //     })
    //     .catch(() => console.log("Not signed in"));
    // },
    async checkSignInMethod(type) {
      let result = Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider[type] });
      console.log("Getting Result", result);
    },
    async App() {
      // const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      //   switch (event) {
      //     case "signIn":
      //       setUser(data);
      //       break;
      //     case "signOut":
      //       setUser(null);
      //       break;
      //     case "customOAuthState":
      //       setCustomState(data);
      //   }
      // });
      // Auth.currentAuthenticatedUser()
      //   .then((currentUser) => setUser(currentUser))
      //   .catch(() => console.log("Not signed in"));
      // return unsubscri
      //   return (
      //   <div className="App">
      //     <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })}>Open Facebook</button>
      //     <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })}>Open Google</button>
      //     <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>
      //     <button onClick={() => Auth.signOut()}>Sign Out {user.getUsername()}</button>
      //   </div>
      // );
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ") e.target.value = e.target.value.replace(/^\s*/, "");
    },
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },
    ConfirmOTPMethod() {
      Auth.forgotPasswordSubmit(this.OTPEmail.trim(), this.ConfirmOTP.trim(), this.ConfirmNewPassword.trim())
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.loginStepper = 1;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        this.ConfirmOTPMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    SendOTPEmail() {
      Auth.forgotPassword(this.OTPEmail.trim())
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.loginStepper = 4;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(this.user, this.Password.ConfirmPassword.trim())
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        const user = await Auth.signIn(this.Login.EmailID.trim(), this.Login.Password.trim());
        this.user = user;
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.loginStepper = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ActivateMethod() {
      setTimeout(() => this.$router.push("/ProjectPage"), 500);
    },
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
  },
};
</script>
