<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  data: () => ({}),
  mounted(){
    history.pushState(null, null, '');
    window.addEventListener("popstate", function () {
      window.location.assign("https://www.bugtrakr.com/");
    });
  
  },
};
</script>
<style>
.maxWidthStatus {
  max-width: 150px !important;
}
.primaryColor {
  color: #5599ff !important;
}
.PrimaryFontColor {
  font-size: 23px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.PrimaryFontMediumNormalColor {
  font-size: 15px !important;
  font-family: Arial, Helvetica, sans-serif;
}
.PrimaryFontMediumBoldColor {
  font-size: 20px !important;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.FontSize {
  font-size: 12px !important;
}
.GreenHeader {
  color: green;
}
.RedHeader {
  color: red;
}
.BlackHeader {
  color: black;
  font-weight: bold;
}
.BlueHeader {
  color: blue;
}
.maxWidthLarge {
  max-width: 250px !important;
}
.maxWidthXLarge {
  max-width: 350px !important;
}
.maxWidth {
  max-width: 200px !important;
}
.maxWidthSmall {
  max-width: 150px !important;
}
.v-input .v-label {
  font-size: 12px;
}
.field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 12px;
}
.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}
.fontSize {
  font-size: 12px !important;
}
.fontSizeLarge {
  font-size: 16px !important;
}
.fontSizeSmall {
  font-size: 10px !important;
}
.borderClass {
  border: 2px solid #5d2e82 !important;
}
.borderLeft {
  border-radius: 0px !important;
  border-left: 1px solid rgb(225, 222, 222) !important;
}
.pointer {
  cursor: pointer;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.borderRadius {
  border-radius: 0px !important;
}
.deepYellowClass {
  color: #ffd600 !important;
}
.borderDotted {
  border: 3px dotted #2161a4 !important;
}
</style>
